import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ResponsiveUsername from './Modules/ClipName';
import {
    akrWhiteboard,
    logo,
    logo1,
    me
} from './Icons';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import {
    america,
    black,
    covidTracker,
    demonTutorsImage,
    goKartDemo,
    goKartDemoThumbnail,
    harvardBrochure,
    harvardPlusKasim,
    malakeyeDemoThumbnail,
    mvcThumbnail,
    upennPlusKasim
} from './CollegeIcons';
import Carousel from './Modules/Carousel';

const universitiesContent = {
    default: {
        threeWords: {
            thirdWordTitle: "Sports",
            thirdWordText: `
I used to think "Veritas" simply meant Harvard in Latin, but it's more profound.

While Widener Library features the word prominently, few can explain its full significance. Most interpret it as "truth," which resonates with my commitment to honesty and integrity.

To me, Veritas symbolizes the pursuit of knowledge and reality. **At Harvard,** I intend to explore truth through challenging coursework and research. This dedication not only drives my academic goals but also aligns with Harvard's emphasis on Veritas, reflecting my identity as a dedicated learner and truth-seeker.
`
        },
        americanText: `
I wasn’t present for the Boston Tea Party, and no one I know has firsthand experience either.

To understand its legacy, I look to Harvard University, a longstanding institution since that pivotal event.

A core part of my American identity is prioritizing our nation's values. I plan to attend Harvard to honor this by introducing Chicago staples like deep-dish pizza and The Bean to the campus community. Additionally, I plan to integrate my Chicago experiences into my studies, using Harvard’s liberal arts approach to complement my focus on computer science and mathematics.
`,
        brochureText: `
Words can't fully capture my love for Harvard, but I'll try. Here's my quick why:

### **Sundae Sundays** 
I have a sweet tooth, and **Sundae Sundays** are proof that Harvard knows how to balance rigor with fun. These events are perfect for me, allowing me to unwind while structuring my day, and maybe heading to Lamont afterward!

### **The Theoretical**
Harvard’s computer science classes emphasize theoretical foundations, which fits perfectly with my passion for deep understanding and innovation. Taking Harvard CS classes will equip me with the critical thinking skills necessary to solve complex problems and make meaningful contributions to the field, making Harvard the ideal place for my academic and professional growth.

## **Contributing to Retention**  

Here's what I've done in the past 4 years to make sure I'm well capable of taking Harvard classes:

- **Collaboration with Harvard Students:** Engaging with current students has given me valuable insights into their study methods and confirmed my ability to thrive in Harvard’s academic environment.  

- **Extensive CS Coursework:** I’ve taken every possible computer science course at my high school and community college, along with advanced courses like Multivariable Calculus (MATH 21A/B) and AP Physics C (PHYSCI 12A/B) to strengthen my foundation. I’m all about learning the nitty-gritty details to continuously improve and refine my skills!

- **Commitment to Learning:** Completing over 20 Coursera and edX courses, including the renowned *CS50*, has strengthened my computational skills and deepened my passion for learning.  

## **Contributing to Yield**  

- **Engaging with Prospective Students:** I plan to actively participate in admissions events and panels to share my Harvard journey and experiences with admitted students.  

- **Building Excitement:** I plan to highlight Harvard’s unique combination of rigorous academics and vibrant campus life, including traditions like **Sundae Sundays**, to make the decision to attend Harvard an easy one.  

I care about Harvard, even the things I don't yet fully understand. As a (hopefully) future Harvard alum, I care deeply about **Harvard's yield and retention rates**. 
I hope with all of this, you truly consider me. My passion for Harvard is genuine, and my commitment to making it my first choice is unwavering.
`,
        bottomMostImage: harvardPlusKasim,
    },
    princeton: {
        threeWords: {
            thirdWordTitle: "Creativity",
            thirdWordText: `
At **Princeton**, the spirit of innovation thrives. I’ve always been drawn to pushing boundaries, and that’s exactly what I plan to do here. My third word—"Creativity"—represents my drive to build, code, and explore new frontiers in computer science, all while embracing every inventive opportunity Princeton offers.
`
        },
        americanText: `
My American identity is all about community, from small gatherings in my Chicago neighborhood to large national events. **At Princeton,** I can't wait to blend my city spirit with a campus culture that champions entrepreneurship and problem-solving, leaving a positive mark wherever I go.
`,
        brochureText: `
**Why Princeton?** Three quick reasons:

1. **Forward-Looking Courses**: Princeton’s CS department champions research in AI and robotics, which aligns perfectly with my own career vision.
2. **Collaborative Hubs**: Makerspaces around campus where I can tinker with gadgets, 3D printers, and VR setups, fueling my love for inventive projects.
3. **Limitless Curiosity**: Princeton is known for fueling intellectual curiosity, ensuring I'd be surrounded by peers and faculty who challenge me to think bigger, break molds, and approach every problem from fresh angles.
`,
        bottomMostImage: upennPlusKasim,
    },
    duke: {
        threeWords: {
            thirdWordTitle: "BlueDevil",
            thirdWordText: `
I’ll be honest: The day I put on my first Duke t-shirt, I felt an instant sense of pride and excitement. “BlueDevil” is my third word because I cannot wait to join the Cameron Crazies, indulge in the synergy of academics and athletics, and bring my programming passion to a campus full of unstoppable energy.
`
        },
        americanText: `
Being American doesn’t just mean living in one place; it’s about unity and shared values. Duke’s commitment to public service and research resonates with my eagerness to give back. I want to embody these values by contributing to projects that empower communities both locally and globally.
`,
        brochureText: `
**Why Duke?** 
- **Sports & Spirit**: The Cameron Crazies reflect a vibrancy I want to be a part of.  
- **Academic Excellence**: With top-tier computer science and engineering programs, Duke provides the perfect balance of theory and hands-on research.
- **Global Impact**: DukeEngage and other service-driven programs align perfectly with my goal of making real-world contributions while still a student.
`,
        bottomMostImage: upennPlusKasim,
    },
};

let aboutMeText = `
Yes, **all of this was made entirely by me**!

Everything here is also **mobile-friendly**.

Please enjoy!!

*P.S.* I'm looking forward to an interview where I can demonstrate everything, especially my passion, in depth. 
`;

const throttle = (func, delay) => {
    let lastCall = 0;
    return (...args) => {
        const now = Date.now();
        if (now - lastCall >= delay) {
            lastCall = now;
            func(...args);
        }
    };
};

const University = ({ setDisableNavbar, universityName = "" }) => {
    const [scrolledDown, setScrolledDown] = useState(false);
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const [isVisible, set3WordsVisible] = useState(false);
    const [bgColor, setBgColor] = useState('#ffffff');
    const latestAdventureRef = useRef(null);
    const convertBlackRef = useRef(null);
    const threeWordRef = useRef(null);
    setDisableNavbar(true);

    const selectedUniKey = universityName.toLowerCase();
    const selectedUniContent = universitiesContent[selectedUniKey] || universitiesContent.default;

    const {
        threeWords,
        americanText,
        brochureText,
        bottomMostImage
    } = selectedUniContent;

    const thirdWordTitle = threeWords?.thirdWordTitle || universitiesContent.default.threeWords.thirdWordTitle;
    const thirdWordText = threeWords?.thirdWordText || universitiesContent.default.threeWords.thirdWordText;

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 60 && !scrolledDown) {
                setScrolledDown(true);
            } else if (window.scrollY < 60 && scrolledDown) {
                setScrolledDown(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolledDown]);

    const observerCallbackRef = useRef(
        throttle((entries) => {
            const isAnyIntersecting = entries.some(entry => entry.isIntersecting);
            if (isAnyIntersecting) {
                setIsVideoVisible(true);
                setBgColor('#000000');
            } else {
                setIsVideoVisible(false);
                setBgColor('#ffffff');
            }
        }, 0)
    );

    const intervalRef = useRef(null);

    useLayoutEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(observerCallbackRef.current, observerOptions);

        if (convertBlackRef.current) {
            observer.observe(convertBlackRef.current);
        }

        return () => {
            if (convertBlackRef.current) {
                observer.unobserve(convertBlackRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    set3WordsVisible(true);
                }
            },
            { threshold: 0.1 }
        );

        if (threeWordRef.current) {
            observer.observe(threeWordRef.current);
        }

        return () => {
            if (threeWordRef.current) {
                observer.unobserve(threeWordRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (bgColor === '#000000') {
            intervalRef.current = setInterval(() => {
                if (convertBlackRef.current) {
                    const rect = convertBlackRef.current.getBoundingClientRect();
                    const isVisible = (
                        rect.top < window.innerHeight &&
                        rect.bottom > 0 &&
                        rect.left < window.innerWidth &&
                        rect.right > 0
                    );

                    if (!isVisible) {
                        setBgColor('#ffffff');
                        setIsVideoVisible(false);
                        clearInterval(intervalRef.current);
                        intervalRef.current = null;
                    }
                } else {
                    setBgColor('#ffffff');
                    setIsVideoVisible(false);
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            }, 500);
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [bgColor]);

    useEffect(() => {
        const video = latestAdventureRef.current;
        if (isVideoVisible) {
            if (video) {
                video.currentTime = 0;
                const playPromise = video.play();
                if (playPromise !== undefined) {
                    playPromise.catch(error => {
                        console.error('Error attempting to play the video:', error);
                    });
                }
            }
        } else {
            if (video) {
                video.pause();
            }
        }
    }, [isVideoVisible]);

    const dashboardStyle = {
        backgroundImage: `url(${akrWhiteboard})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        height: '82vh',
        position: 'relative',
        transition: 'background-color 0.125s ease-in-out, color 0.125s ease-in-out, transform 0.2s ease-in-out, border 0.15s ease-in-out, margin-bottom 0.3s ease-in-out, margin-top 0.3s ease-in-out',
        transform: 'scale(1)',
        marginBottom: '-75px',
    };

    const scrollDashboardStyle = {
        ...dashboardStyle,
        transform: 'scale(0.75)',
        marginBottom: '30px',
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 88%, rgba(255, 255, 255, 1) 98%)',
        zIndex: 1,
    };

    const videoContainerStyle = {
        position: 'relative',
        transition: 'background-color 0.3s ease-in-out',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
    };

    const videoStyle = {
        width: '60%',
        height: 'auto',
        borderRadius: "12px",
        transition: 'width 0.3s ease-out',
    };

    const scrollVideoStyle = {
        ...videoStyle,
        width: '75%',
    };

    const subverbStyle = {
        color: isVideoVisible ? 'white' : 'rgba(0, 0, 0, 0.7)',
        transition: 'color 0.3s ease-in-out',
    };

    const combinedRef = (node) => {
        latestAdventureRef.current = node;
        convertBlackRef.current = node;
    };

    const slides = [
        <div className="slide-content">
            <div className="carouselTextLabelContainer">
                <h2 className="timelineCarosuel">January 2018 - Present</h2>
                <h2 className="carouselItemTitle">Roblox</h2>
                <p className="carouselItemDescription">Built a following of over 100,000 on Roblox, collaborated on games with over 1,000,000,000 visits, and ranked among the top 3,000 wealthiest players while generating nearly $100,000 through avatar accessory sale, also worked with Roblox staff to A/B test beta features to 70,000,000 daily active users!</p>
            </div>
        </div>,
        <div className="slide-content">
            <div className="carouselTextLabelContainer">
                <h2 className="timelineCarosuel">March 2020 - Present</h2>
                <h2 className="carouselItemTitle">AKR</h2>
                <p className="carouselItemDescription">Developed a React.js and Node.js website attracting over 1,000,000 annual visits, built an academic search engine indexing 3,000 research papers, a real-time bidirectional communication system using WebSocket and RTC, and a Java-based two-factor authentication system to enhance security for Akr accounts.
                </p>
            </div>
        </div>,
        <div className="slide-content">
            <div className="carouselTextLabelContainer">
                <h2 className="timelineCarosuel">August 2023 - Present</h2>
                <h2 className="carouselItemTitle">Maine East High School</h2>
                <p className="carouselItemDescription">Managed various IT tasks at my high school by repairing Chromebooks, updating websites for over 6,000 students/staff, overseeing network uptime, and customer service at the IT front desk.</p>
            </div>
        </div>,
    ];

    const projectSlides = [
        <div
            className="slide-content"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${demonTutorsImage})`,
            }}>

            <div className="slideContentMain">
            </div>
            <div className="carouselTextLabelContainer invertText">
                <h2 className="carouselItemTitle">Demon Tutors</h2>
                <p className="carouselItemDescription">Initiated the "Demon Tutors" program at my local high school, creating a centralized website that connects students with personalized tutors based on subject and availability. This digital solution enhanced academic support for peers while offering tutors valuable service hours and resume-building opportunities.</p>
            </div>
        </div>,
        <div
            className="slide-content"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${covidTracker})`,
            }}>
            <div className="slideContentMain">
            </div>
            <div className="carouselTextLabelContainer invertText">
                <h2 className="carouselItemTitle">Covid Tracker</h2>
                <p className="carouselItemDescription">In the height of the pandemic, I created a COVID-19 tracker for Advocate Lutheran General Hospital, featuring vaccine availability by scraping CVS and Walgreens APIs and real-time case statistics from local school district websites. Although it saw limited use, the project significantly enhanced my skills in web scraping, API integration, and data presentation.</p>
            </div>
        </div>,
        <div
            className="slide-content"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${goKartDemoThumbnail})`,
            }}>
            <div className="slideContentMain">
            </div>
            <div className="carouselTextLabelContainer invertText">
                <h2 className="carouselItemTitle">Self Driving Go-Kart</h2>
                <p className="carouselItemDescription">I built an autonomous go-kart equipped with cameras for live vision processing, obstacle detection, and pathfinding, integrating neural networks for real-time object recognition and dynamic obstacle avoidance. Additionally, I programmed an infotainment system with smartphone connectivity, incorporating third-party apps like Spotify and enabling live map navigation through trained data.</p>
            </div>
        </div>,
        <div
            className="slide-content"
            style={{
                backgroundImage: `url(${mvcThumbnail})`,
            }}>
            <div className="slideContentMain">
            </div>
            <div className="carouselTextLabelContainer invertText">
                <h2 className="carouselItemTitle">Multivariable 3D Calculator</h2>
                <p className="carouselItemDescription">Frustrated by the limitations of 2D graphing calculators like Desmos and the TI-84, I created a custom MVC calculator that enables real-time equation visualization, interactive point hovering, and viewing of value tables. My classmates and I use this in class frequently!</p>
            </div>
        </div>,
        <div
            className="slide-content"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${malakeyeDemoThumbnail})`,
            }}>
            <div className="slideContentMain">
            </div>
            <div className="carouselTextLabelContainer invertText">
                <h2 className="carouselItemTitle">Malakeye</h2>
                <p className="carouselItemDescription">I integrated OpenAI’s ChatGPT into Akr via API to create a "study persona," providing personalized study assistance that enabled me and my peers to achieve top placements in state-level DECA competitions. Additionally, I utilized Web Sockets for real-time communication, facilitating interactive study sessions and fostering a collaborative learning environment.</p>
            </div>
        </div>,
    ];

    return (
        <>
            <div style={{ backgroundColor: bgColor, transition: 'background-color 0.35s ease-in-out' }}>
                <div style={scrolledDown ? scrollDashboardStyle : dashboardStyle}>
                    <nav className="navbar">
                        <Link to="/" className="navbarLogoLink">
                            <img className="navbar1Logo" src={logo1} alt="Akr" />
                        </Link>
                    </nav>
                    <div style={overlayStyle} />
                </div>
                <div className="aweaweaew-def">
                    <div className="outer-def-container">
                        <div className="inner-def-left">
                            <div className="collegeSpaceSection101">
                                <div className="user-name-container">
                                    <ResponsiveUsername fullName={`Hey, ${universityName}`} className="dashboardProfileNameNew" />
                                    <div className="smallerDashboardMotiveText">Admissions can be hard, here's this instead!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="akrNewAboutMeContainer">
                    <div className="aboutMeCollageContentContainer">
                        <div className="aboutMeCollageImage">
                            <img src={me} className="collegeSectionImageCustomRound" alt="" />
                        </div>
                        <div className="aboutMeCollageText">
                            <ReactMarkdown remarkPlugins={[gfm]} components={{
                                img({ node, ...props }) {
                                    return <img {...props} alt="" className="markdown-image" />
                                },
                            }}>
                                {aboutMeText}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
                <div className="subverbSpace">
                    <div className="inner-def-left">
                        <div className="subverbNew">Me In 3 Words</div>
                    </div>
                </div>
                <div className='threeBoxDivCollege'>
                    <div className={`threeBoxDivCollegeDiv ${isVisible ? "threeWordsVisible" : ""}`} ref={threeWordRef}>
                        <div className="contentWrapper3Words">
                            <div className="graphicSection3Words">
                                <div className="imageBackground3Words">
                                    <img src={black} alt="Hungry" className="mainImage3Words" />
                                </div>
                                <div className="overlatText3Words">Hungry</div>
                            </div>
                            <div className="infoSection3Words">
                                <div className='subverbBottom'>
                                    <ReactMarkdown remarkPlugins={[gfm]} components={{
                                        img({ node, ...props }) {
                                            return <img {...props} alt="" className="markdown-image" />
                                        },
                                    }}>
                                        {`
The highlight of my day, honestly, is choosing where to eat!

**At ${universityName},** I plan to have breakfast after class, hoping to have chia and French toast.

**As a Muslim,** having access to halal food at every dining hall is a must-have that a lot of schools overlook, **but not ${universityName}!** 

**During Ramadan at ${universityName},** I know that I will be fully supported through dedicated meal options and engaging community events.
`}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                        <div className="contentWrapper3Words">
                            <div className="graphicSection3Words">
                                <div className="imageBackground3Words">
                                    <img src={america} alt="American" className="mainImage3Words" />
                                </div>
                                <div className="overlatText3Words">American</div>
                            </div>
                            <div className="infoSection3Words">
                                <div className='subverbBottom'>
                                    <ReactMarkdown remarkPlugins={[gfm]} components={{
                                        img({ node, ...props }) {
                                            return <img {...props} alt="" className="markdown-image" />
                                        },
                                    }}>
                                        {americanText}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                        {/* 3rd Word (DYNAMIC) */}
                        <div className="contentWrapper3Words">
                            <div className="graphicSection3Words">
                                <div className="imageBackground3Words">
                                    <img src={black} alt="Sports" className="mainImage3Words" />
                                </div>
                                <div className="overlatText3Words">{thirdWordTitle}</div>
                            </div>
                            <div className="infoSection3Words">
                                <div className='subverbBottom'>
                                    <ReactMarkdown remarkPlugins={[gfm]} components={{
                                        img({ node, ...props }) {
                                            return <img {...props} alt="" className="markdown-image" />
                                        },
                                    }}>
                                        {thirdWordText}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subverbSpace">
                    <div className="inner-def-left">
                        <div className="subverbNew" style={subverbStyle}>
                            Latest Endeavour
                        </div>
                    </div>
                </div>
                <div style={videoContainerStyle}>
                    <div style={videoContainerStyle} >
                        <video
                            src={goKartDemo}
                            ref={combinedRef}
                            autoPlay
                            muted
                            playsInline
                            loop
                            style={isVideoVisible ? scrollVideoStyle : videoStyle} >
                            Your browser does not support the video tag.
                        </video>
                        <p className="goKartVideoDescription">
                            User Interface (UI) for the self-driving car I built. Many features are currently disabled because the demo isn't connected to the actual go-kart. When connected, the system will use the go-kart's hardware to enable all intended functionalities. This go-kart uses sensors and cameras to navigate automatically and intelligently, integrating speed limit information from both onboard cameras and online sources to ensure compliance with all traffic rules.
                        </p>
                    </div>
                </div>
                <div className="subverbSpace smallMargainSubverb">
                    <div className="inner-def-left">
                        <div className="subverbNew" style={subverbStyle}>
                            {`I ❤️ ${universityName}`}
                        </div>
                    </div>
                </div>
                <div className="flexAndCenterAndAllignDisplay">
                    <div className='aboutMeCollageHarvard imageWithCaptionFramework'>
                        <img src={harvardBrochure} className="collegeSectionImageCustomRound" alt="" />
                        <p className="imageCaptionHarvard">
                            <ReactMarkdown remarkPlugins={[gfm]} components={{
                                img({ node, ...props }) {
                                    return <img {...props} alt="" className="markdown-image" />
                                },
                            }}>
                                {brochureText}
                            </ReactMarkdown>
                        </p>
                    </div>
                </div>
                <div className="subverbSpace smallMargainSubverb">
                    <div className="inner-def-left">
                        <div className="subverbNew" style={subverbStyle}>
                            Projects
                        </div>
                    </div>
                </div>
                <Carousel slides={projectSlides} autoPlay={false} autoPlayTime={5000} />
                <div className="subverbSpace smallMargainSubverb">
                    <div className="inner-def-left">
                        <div className="subverbNew" style={subverbStyle}>
                            Experience
                        </div>
                    </div>
                </div>
                <Carousel slides={slides} autoPlay={false} autoPlayTime={5000} version={2} />
                <div className="subverbSpace smallMargainSubverb">
                    <div className="inner-def-left">
                        <div className="subverbNew" style={subverbStyle}>
                            Final Thoughts
                        </div>
                    </div>
                </div>
                <div className="flexAndCenterAndAllignDisplay">
                    <div className='aboutMeCollageHarvard imageWithCaptionFramework'>
                        <img src={bottomMostImage} className="collegeSectionImageCustomRound" alt="" />
                        <p className="imageCaptionHarvard">
                            <ReactMarkdown remarkPlugins={[gfm]} components={{
                                img({ node, ...props }) {
                                    return <img {...props} alt="" className="markdown-image" />
                                },
                            }}>
                                {`
Dear ${universityName} Admissions Committee,

Thank you for reviewing my application and taking the time to explore my website.

I hope to use ${universityName}'s extraordinary environment to advance research in mathematics and robotics, ultimately creating breakthroughs that make the world a better place. Over time, I plan to serve here as a professor, guiding others as we uncover new possibilities together.

In the words of Isaac Newton, “If I have seen further, it is by standing on the shoulders of giants,” and I believe ${universityName} provides the shoulders on which I can stand and contribute to a brighter future.

Thanks once again,

Kasim Akram`}
                            </ReactMarkdown>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default University;