import React, { useState, useCallback } from 'react';
import ImageCacheContext from './ImageCacheContext';

const ImageCacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const addImageToCache = useCallback((key, src) => {
    setCache((prevCache) => ({ ...prevCache, [key]: src }));
  }, []);

  const getImageFromCache = useCallback((key) => {
    return cache[key];
  }, [cache]);

  return (
    <ImageCacheContext.Provider value={{ addImageToCache, getImageFromCache }}>
      {children}
    </ImageCacheContext.Provider>
  );
};

export default ImageCacheProvider;