import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Carousel.css';

const Carousel = ({ slides, autoPlay = false, autoPlayTime = 10000, version = 1 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = slides.length;

  const prevIndex = (currentIndex - 1 + length) % length;
  const nextIndex = (currentIndex + 1) % length;

  useEffect(() => {
    let interval = null;
    if (autoPlay) {
      interval = setInterval(() => {
        nextSlide();
      }, autoPlayTime);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentIndex, autoPlay, autoPlayTime]);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + length) % length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getSlideClass = (i) => {
    if (i === currentIndex) return 'slide current-slide';
    if (i === prevIndex) return 'slide prev-slide';
    if (i === nextIndex) return 'slide next-slide';
    return 'slide hidden-slide';
  };

  return (
    <>
      <div
        className={`carousel-wrapper ${version === 2 ? 'carosel1' : ''}`}
        {...handlers}
      >
        {slides.map((slideContent, i) => (
          <div
            key={i}
            className={getSlideClass(i)}
            onClick={() => {
              if (i === prevIndex) goToSlide(prevIndex);
              if (i === nextIndex) goToSlide(nextIndex);
            }}
          >
            {slideContent}
          </div>
        ))}
      </div>
      <div className="dots-container">
        {slides.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'dot active-dot' : 'dot'}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </>
  );
};

export default Carousel;
