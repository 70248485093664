import React, { Component } from 'react';

class ResponsiveUsername extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  renderUsername = (fullName) => {
    if (!fullName) return '';

    if (this.state.windowWidth < 600) {
      if (fullName.length > 16) {
        const firstName = fullName.split(' ')[0];
        if (firstName.length > 8) {
          return `${firstName.substring(0, 6)}...`;
        }
        return firstName;
      }
      return fullName;
    }
    return fullName;
  };

  render() {
    const { fullName, className } = this.props;
    return (
      <span className={className}>
        {this.renderUsername(fullName)}
      </span>
    );
  }
}

export default ResponsiveUsername;
